.ant-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  line-height: 21px;
  border: 0;
  border-radius: 16px;

  &:hover {
    opacity: 1;
  }

  &.Content.Marketing {
    color: #ffffff;
    background: #712eff;
  }

  &.Conversion.Optimization {
    color: #000000;
    background: #fcfd65;
  }

  &.Lead.Generation {
    color: #ffffff;
    background: #0072ff;
  }

  &.SEO {
    color: #000000;
    background: #ff9612;
  }

  &.Future.Advertising {
    color: #ffffff;
    background: #d31027;
  }

  &.Performance.Design.Creatives {
    color: #000000;
    background: #d4fc79;
  }

  &.Social.Media.Marketing.Influencers {
    color: #ffffff;
    background: #7f00ff;
  }

  &.Ecommerce.D2C {
    color: #ffffff;
    background: #001fff;
  }

  &.Email.Marketing.Automation {
    color: #ffffff;
    background: #02aab0;
  }

  &.Media.Buying {
    color: #000000;
    background: #5effd8;
  }

  &.Growth.Hacking {
    color: #ffffff;
    background-image: #ff635a;
  }

  &.Other,
  &.Break {
    color: #ffffff;
    background: #02aab0;
  }
}
