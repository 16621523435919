.auth-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin-top: -76px;
  background: url(../../../images/adw/pro/Login.png) center / cover;
  padding-top: 20px;
  padding-right: 15px;

  .adworld-logo {
    padding-bottom: 20px;
    text-align: center;
  }

  &-form-container {
    z-index: 1;
    width: 550px;
    max-width: 95%;
    padding: 70px;
    margin: 25px 15px 50px 15px;
    background: 0;
    border: none;
    position: relative;
    background: @login-popup-background;
    box-shadow: @login-popup-box-shadow;
    border-radius: @login-popup-border-radius;

    @media (max-width: 500px) {
      padding-right: 25px;
      padding-left: 25px;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      z-index: 2;

      img {
        height: 60px;
      }

      .title {
        font-size: 23px;
        color: rgb(24, 26, 85);
        text-transform: uppercase;
        letter-spacing: 0.1em;

        strong {
          font-weight: 700;
        }
      }
    }

    .ant-form {
      .ant-btn {
        margin-top: 10px;
      }
      &-item {
        padding: 0 25px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.password-form-item {
          margin-bottom: 7px;
        }

        &.ant-form-item-has-error {
          .ant-form-item-control-input {
            .ant-input-affix-wrapper {
              border-color: rgb(253, 69, 78);
            }
          }
        }

        &-control-input {
          .ant-input-affix-wrapper {
            padding: 8px 12px;
            border: 1px solid #F2F2F2;
            border-radius: 2px;
            box-shadow: none;

            .ant-input-prefix {
              margin-right: 10px;

              svg {
                color: #181922;
              }
            }

            input {
              font-size: 15px;
              color: #181922;
            }
            input::placeholder {
              color: #181922;
            }
          }

          &-content {
            > input {
              padding: 8px 12px;
              font-size: 15px;
              border: 1px solid #F2F2F2;
              border-radius: 2px;
              box-shadow: none;
            }
          }
        }
        .ant-form-item-explain {
          margin-bottom: 10px;
        }
      }

      .ant-form-item{
        margin-bottom: 0px;
      }

      hr {
        height: 1px;
        margin: 15px 0;
        background-color: rgb(224, 224, 224) !important;
        border: none;
      }

      .forgot-password {
        padding: 0 25px;
        margin-bottom: 14px;
        font-weight: 700;
        text-align: right;

        a {
          font-size: 15px;
          color: @login-a-color;
          background: @login-a-bg;
          -webkit-text-fill-color: @login-a-bg-clip;
          text-fill-color: @login-a-bg-clip;
          -webkit-background-clip: text;
          background-clip: text;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .didnt-receive-login {
        font-size: 14px;
        font-weight: 700;
        color: @login-a-color;
        background: @login-a-bg;
        -webkit-text-fill-color: @login-a-bg-clip;
        text-fill-color: @login-a-bg-clip;
        -webkit-background-clip: text;
        background-clip: text;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
