.ticket-section-wrapper {
  &.clickable:hover {
    cursor: pointer;
  }

  &:not(.clickable) {
    .daily-raffle-item {
      opacity: 0.5;
    }
  }
}

#daily-raffle {
  padding: 20px;
  background: #ffffff;
  background-color: #f8f8f8;
  border-radius: 10px;

  h3 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    color: #4b4c53;
    text-align: center;
  }

  h4 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 800;
    line-height: 30px;
    color: #4b4c53;
    text-align: center;
  }

  .daily-raffle-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.2s;

    &:hover {
      background-color: #f8f8f8;
    }

    .daily-raffle-item-details {
      width: 100%;
      margin-top: 5px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;
        color: @btn-color;
        background: @btn-background;
        border: @btn-border;
        box-shadow: @btn-box-shadow;
        outline: none;

        i {
          height: 16px;
          margin-right: 8px;
        }

        span {
          font-size: 16px;
          font-style: normal;
          font-weight: bold;
          line-height: 20px;
          color: @secondary;
        }
      }
    }
  }
}
