.social-post {
  position: relative;
  padding: 20px 15px;
  background-color: @white;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
  max-width: 700px;

  a:hover {
    text-decoration: none;
    cursor: default;
  }

  .post-video-player iframe {
    width: 100%;
  }

  .content {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.62px;
    color: #4b4c53;
    white-space: pre-wrap;

    * {
      max-width: 100%;
    }

    img {
      width: 100%;
      margin-top: 20px;
      overflow: hidden;
      border-radius: 10px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    a {
      color: #1b9ae9 !important;
      cursor: pointer;

      &:hover {
        color: #005fd1 !important;
      }
    }
  }

  .avatar {
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #4b4c53;
  }

  .reactions-wrapper {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
  }

  iframe {
    max-width: 100%;
  }
}
