.reaction-container {
  position: relative;
  display: flex;
  max-width: 50px;
  margin: 15px 0 0;
  cursor: pointer;

  &:hover {
    .reaction-icon {
      opacity: 1;
      transform: scale(1.3);
    }
  }

  .reaction-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    font-size: 12px;
    transition: 0.15s ease-in-out;

    img {
      vertical-align: sub;
    }

    .reaction-counter {
      margin-left: 5px;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: #a3a7b3;
    }

    &.liked {
      color: #f96461;

      svg {
        fill: #f96461;
      }
    }
  }
}
