.currently-streaming {
  width: 90%;
  max-width: 1680px;
  padding: 0;
  margin: auto;

  @media screen and (max-width: 1400px) {
    width: 80%;
  }

  .slick-arrow.slick-prev {
    top: 50% !important;
    left: -16px !important;
    width: 60px;
    height: 60px;
    background: @white !important;
    border-radius: 50%;

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-arrow.slick-next {
    top: 50% !important;
    transform: translateY(-50%);
    right: -16px !important;
    width: 46px;
    height: 46px;
    background: @white !important;
    border-radius: 50%;

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-list {
    margin-top: 60px;
    margin-bottom: 15px;
  }

  h5 {
    flex-grow: 1;
    padding: 1rem 20px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: bold;
    line-height: 20px;
    color: #4b4c53;
  }

  .ant-carousel {
    width: 100%;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .slick-track {
      display: flex !important;
      margin: auto;
    }

    .slick-slide {
      height: inherit !important;
      padding: 0 15px;

      & > div {
        height: 100%;
      }
    }
  }

  .subtitle {
    font-size: 12px;
    font-weight: bold;

    span:first-child {
      display: inline-block;
      padding: 0 3px;
      line-height: 18px;
      color: @white;
      background-color: @purple-100;
      border-radius: 2px;
    }

    span:last-child {
      margin-left: 10px;
      color: #181a55;
    }
  }

  .streaming-item {
    display: inline-flex !important;
    flex-direction: column;
    width: 100%;
    height: 94%;
    padding-bottom: 15px;
    overflow: hidden;
    pointer-events: all;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

    &:hover {
      cursor: pointer;
    }

    &-time {
      padding-left: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      color: #a3a7b2;
    }

    .tag-wrapper {
      padding: 0 20px;
    }
  }

  .blur-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  .streaming-button-wrapper {
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 12px;
    color: @white;

    span {
      padding: 0 5px 1px;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      background: #e91916;
      border-radius: 4px;
    }
  }

  button {
    display: none;
    width: 26px;
    height: 26px;
    padding: 0;
    color: @white;
    background: @red-100;
    border: none;
    border-radius: 9999px;
  }

  .play-button-stream {
    width: 50px;
    height: 50px;
    background: transparent;
    border: 5px solid #ffffff;
    opacity: 0.8;
    transition: opacity 0.05s ease-in-out;

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-top: 10px;
      margin-left: 12px;

      path {
        fill: #ffffff;
      }
    }
  }

  .preview {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      .blur-filter {
        opacity: 1;
      }
    }
  }
}
