.header-input {
  background-color: #FDFCFF1A;

  input {
    color: white;
    background-color: transparent;
    font-weight: 500;
  }
}

.header-select {
  .ant-select-selector {
    height: 100% !important;
    background-color: #FDFCFF1A !important;
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    font-weight: 450 !important;
    font-size: 14px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.pro-header {
  font-weight: 500;

  a {
    border-bottom: 5px solid transparent;
    padding: 0px 15px;

    span {
      vertical-align: middle;
    }
  }

  .active {
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: #F75E2E;
    background-color: rgba(253, 252, 255, 0.1) !important;
  }

  .header-logo {
    .active {
      border-bottom: 5px solid transparent;
      background-color: transparent !important;
    }
  }

  .header-user {
    .active {
      border-bottom: none;
      background-color: transparent !important;
    }
  }
}

.pro-ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 30px;
}

.ant-btn-primary {
  color: rgb(255, 255, 255);
  background: linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%);
  box-shadow:
    0px 34px 14px rgba(255, 120, 0, 0.02),
    0px 19px 11px rgba(255, 120, 0, 0.06),
    0px 8px 8px rgba(255, 120, 0, 0.1),
    0px 2px 5px rgba(255, 120, 0, 0.11),
    0px 0px 0px rgba(255, 120, 0, 0.11);

  &:hover, &:active, &:focus {
    color: white !important;
    background: linear-gradient(223.32deg, #F75E2E 0%, #FF9900 97.23%) !important;
  }
}

.ant-btn-default {
  color: #F75E2E;
  border-color: #F75E2E;
  border-width: 2px;

  &:hover, &:active, &:focus {
    color: #FF9900 !important;
    border-color: #FF9900 !important;
  }
}

.pro-modal {
  .ant-modal-content {
    border-radius: 20px;
  }
}

.pro-menu {
  background-color: "transparent";
  border-right: none;

  .ant-menu-item {
    color: rgba(24, 25, 34, 0.5);
    padding: 0px;

    &:hover {
      color: #181922 !important;

      & svg {
        color: #181922 !important;
      }
    }
  }

  .ant-menu-item-selected {
    color: #181922 !important;

    & svg {
      color: #181922 !important;
    }
  }
}

.pro-ant-collapse {
  background-color: transparent;
  border: none;

  .ant-collapse-item {
    .ant-collapse-header {
      border-bottom: 1px solid rgba(36, 38, 45, 0.3);
      border-radius: 0px !important;

      h1 {
        margin-bottom: 0px;
        padding: 10px 0;
      }
    }
  }

  .ant-collapse-header {
    padding: 0px !important;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    background-color: transparent !important;

    .ant-collapse-arrow {
      margin-bottom: "15px";
      display: flex !important;
      position: relative !important;
      margin-left: auto;
      padding: 0px 15px !important;
      margin-bottom: 5px;
    }
  }

  .ant-collapse-item {
    border-radius: 0px !important;
  }

  .ant-collapse-content {
    background-color: transparent;

    .ant-collapse-content-box {
      padding: 20px 0;
    }
  }

  input, select, textarea, .ant-select > .ant-select-selector {
    border-color: transparent !important;
  }
}

.pro-card {
  border-radius: 23px;
}

.pro-input {
  &.ant-input-affix-wrapper {
    line-height: 23px !important;
    border-radius: 9px !important;
  }
}

.pro-checkbox-filter {
  width: 200px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(253, 252, 255, 0.102);
  border: 1px solid rgb(67, 68, 74);
  overflow: hidden;

  .ant-dropdown-trigger {
    width: 100%;
  }
}

.pro-checkbox-menu {
  z-index: 2000;

  .column-checkbox {
    margin-top: 4px;
  }

  .spacer-div {
    display: none;
  }
}

.search-menu-item .header-input {
  border-color: rgb(67, 68, 74);
  border-right: 1px solid #FDFCFF;
}

.search-menu-item {
  &.ant-menu-item-disabled,
  &.ant-menu-submenu-disabled {
    cursor: auto !important;
  }
}

.ant-layout-header .ant-menu-horizontal {
  border: 0;
}

.trending-talk-container {
  margin: 50px 120px 0 120px;

  @media only screen and (max-width: 575px) {
    margin: 10px;

    > .ant-row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.talks-container {
  margin: 0 0 50px 120px;

  .title-row {
    margin-right: 120px;
  }

  @media only screen and (max-width: 575px) {
    margin: 10px;

    .title-row {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 575px) {
  .my-list-container {
    margin: 50px 120px;
  }
}

.community {
  &-container {
    display: flex;
    flex-flow: column;
    text-align: center;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    @media only screen and (max-width: 575px) {
      .flex-gap {
        display: none;
      }
    }
  }

  &-title {
    font-size: 35px;
    line-height: 45px;
    color: #181922;
    margin-top: 50px;

    @media only screen and (max-width: 575px) {
      font-size: 30spx;
    }
  }

  &-text {
    color: #181922;
    font-size: 18px;
  }

  &-button {
    margin-top: 10px;
    padding-top: 30px !important;
  }

  &-banner {
    width: 50%;
    margin-bottom: -1%;

    @media only screen and (max-width: 575px) {
      width: 100%;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .ant-menu-item-only-child:first-child {
    margin-top: 20px;
  }

  .search-menu-item {
    margin-bottom: 16px !important;
  }

  .ant-upload-picture-card-wrapper {
    transform: scale(0.75);
  }

  .pro-ant-btn {
    padding: 25px;
    margin: 5px 0;
  }

  .pro-modal {
    width: 100% !important;

    .ant-modal-body {
      padding: 33px !important;
    }
  }

  .track-page {
    margin-top: 20px;

    .stage-select {
      display: none !important;
    }

    .talk-desc {
      margin: 0 !important;
    }

    .speaker-information-container {
      gap: 15px;
    }

    .stream-box {
      padding: 10px 7px !important;
    }

    .actions {
      margin-left: -5px !important;
      margin-top: 10px;
    }
  }
}
