.full-screen-mobile {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: black;

  .dc-video-player-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.stream-not-playing {
  padding: 4px 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;

  > span {
    display: inline-block;
    width: 35px;
    min-width: 30px;
    height: 35px;
    min-height: 30px;
    margin-right: 10px;
    line-height: 32px;
    text-align: center;
    background: @red-100;
    border-radius: 50%;

    svg {
      color: white;
      vertical-align: middle;
    }
  }
}

.vimeo-player-container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  transition-property: top, bottom, left, right;

  &.full-screen {
    .dc-video-player-wrapper {
      height: 100vh !important;
    }

    #stage-player {
      height: 100vh !important;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .fullscreen-control {
    z-index: 100;
    display: none;

    &.show {
      display: initial;
    }

    &:hover {
      color: #ffffff;
      cursor: pointer;
    }
  }

  &.nocontrols {
    .vjs-control-bar {
      z-index: 9999;
      display: none !important;
      justify-content: flex-end !important;
      width: initial !important;
      background-color: transparent !important;
      opacity: 1 !important;

      * {
        display: none !important;
      }

      .vjs-fullscreen-control {
        position: absolute;
        z-index: 9999 !important;
        display: none !important;
        color: white;

        text-shadow: 0 0 black;
      }
    }
  }

  .video-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: 0.15s ease-in-out;
  }

  .loading-next-talk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .play-button {
      margin: 40px 0 20px;
    }

    h4,
    h5,
    span {
      color: #ffffff;
    }

    h4 {
      font-size: 22px;
    }

    h5 {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }
  }

  #stage-player {
    height: 500px !important;

    &.jw-flag-fullscreen {
      height: 100vh !important;
    }

    @media screen and (max-width: 600px) {
      height: 300px !important;
    }
  }

  .talk-stage-name {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 900;
    padding: 3px 15px;
    font-size: 15px;
    line-height: 21px;
    color: @white;
    text-transform: uppercase;
    background: #353535ab;
    border-bottom-right-radius: 4px;
  }

  .talk-status {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    font-size: 22px;
    color: @white;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 600px) {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.781);
      text-align: center;
    }

    &.talk-status-streaming {
      top: 15px;
      left: 15px;
      padding: 0 5px 1px;
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
      text-transform: uppercase;
      background: #e91916;
      border-radius: 4px;
      transform: none;
    }
  }

  &.play .play-button-wrapper {
    opacity: 0;
  }

  &.play:hover .play-button-wrapper {
    opacity: 1;
  }

  &.big {
    .dc-video-player-wrapper {
      width: 100% !important;

      @media screen and (max-width: 600px) {
        height: 300px !important;
      }
    }
  }

  &.small {
    .dc-video-player-wrapper {
      width: 100% !important;
      height: 200px !important;
    }
  }

  &.big {
    right: auto;
    bottom: auto;
    width: 100%;
  }

  &.small {
    position: fixed;
    top: auto;
    right: 100px;
    bottom: 20px;
    left: auto;
    width: 300px;
    height: 200px;
    background: black;
    border-radius: 6px;
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  }

  .volume-wrapper {
    position: absolute;
    right: 50px;
    bottom: 12px;
    z-index: 9999;
    display: flex;

    &:hover {
      cursor: pointer;

      svg {
        color: #ffffff;
      }

      input {
        width: 100px;
        opacity: 1;
      }
    }

    input {
      width: 1px;
      padding: 0;
      opacity: 0;

      &:hover {
        cursor: pointer;
      }

      &[type="range"] {
        height: 5px;
        margin-top: 10px;
        background-color: #ffffff;
        border: 1px solid #bdc3c7;
        border-radius: 8px;
        appearance: none;
      }
    }

    .volume-icon {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }

    &.mobile {
      input {
        width: 100px;
        opacity: 1;
      }
    }
  }
}
