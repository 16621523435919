@mainContainerBackgroundColor: #f2f3fa;
@chatIconHoverColor: @standout;

.booth-container {
  flex: 1;
  padding: 30px 20px 60px;
  background: @mainContainerBackgroundColor;

  .booth-view-container {
    display: flex;

    .left-container {
      flex: 0 1 60%;
      max-width: 60%;
      min-height: 100%;
      margin-right: 30px;

      .booth-card {
        margin-bottom: 35px;
        overflow: hidden;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

        .card-company-info.inverted {
          .card-company-name,
          .card-company-description .card-company-address,
          .card-company-description .card-company-email {
            color: #1c1c1d;
          }
        }

        .card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 25px 23px;
          color: #ffffff;
          background: #331eb6;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          flex-wrap: wrap;
          gap: 10px;

          .card-company-description {
            display: flex;
            flex-direction: row;

            .card-company-description-email {
              a {
                font-weight: bold;
                color: #ffffff;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          @media screen and (max-width: 1024px) {
            .card-company-description {
              flex-direction: column;
              align-items: baseline;
            }
          }

          .card-title {
            display: flex;
            align-items: center;

            h4 {
              margin: 0;
              margin-bottom: 9px;
              font-size: 24px;
              font-weight: bold;
              line-height: 30px;
              color: #ffffff;
            }

            .card-company-description {
              .card-company-address {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                font-size: 16px;
                color: #ffffff;

                svg {
                  margin-right: 5px;
                  vertical-align: middle;
                }

                &.card-company-address {
                  font-weight: normal;
                }

                &.card-company-email {
                  font-weight: bold;
                  text-decoration: underline;
                }
              }
            }

            .card-company-description-email {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 15px;
                height: 20px;
                margin-right: 7px;
              }
            }
          }

          .card-website {
            padding-top: 4px;
            margin-left: auto;

            a {
              display: flex;
              padding: 15px 40px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
              color: @secondary;
              background: @primary;
              border-radius: 10px;
            }
          }
        }

        .card-content {
          padding: 27px 23px;

          h4 {
            font-size: 24px;
            font-weight: bold;
            line-height: 23px;
            color: #4b4c53;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #a3a7b3;
          }

          .tags {
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 50px;
            gap: 20px;

            .tag-item {
              display: flex;
              margin-right: 22px;

              .check-icn {
                align-self: center;

                svg {
                  width: 32px !important;
                  height: 32px !important;
                }
              }

              .tag-title {
                padding-left: 1rem;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #4b4c53;
              }
            }
          }

          .lk-for {
            p {
              margin: 0 10px 10px 0;
              font-size: 24px;
              font-weight: bold;
              line-height: 30px;
              color: #4b4c53;
            }
          }
        }

        .job-hiring-container {
          padding: 17px 23px;

          p {
            margin: 0 10px 10px 0;
            font-size: 24px;
            font-style: normal;
            font-weight: bold;
            color: #4b4c53;
          }

          a {
            display: block;
            display: inline-block;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 25px;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: @btn-color;
            background: @btn-background;
            box-shadow: @btn-box-shadow;
            border: @btn-border;
            border-radius: @btn-border-radius;
          }
        }
      }
    }

    .wrapper-container {
      display: flex;
      flex: 0 1 40%;
      flex-wrap: wrap;
      align-items: flex-start;
      height: 100%;
      border-radius: 10px;
    }

    .right-container {
      display: flex;
      flex-wrap: wrap;

      align-items: flex-start;
      width: 100%;
      margin-bottom: 26px;
      background: #ffffff;
      border-radius: 10px;

      h4 {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .chat-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      padding: 20px;
      margin-bottom: 28px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

      .contact-container {
        width: inherit;

        .title-hd {
          font-size: 24px;
          font-style: normal;
          font-weight: bold;
          line-height: 36px;
          color: #4b4c53;
        }

        .contact-listing {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          width: 100%;

          .contact-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 25px 18px 25px 17px;
            margin-bottom: 20px;
            background: rgba(245, 247, 255, 0.7);
            border-radius: 10px;
            transition: 0.2s;

            &:last-child {
              margin: 0;
            }

            svg {
              path {
                transition: 0.2s;
              }
            }

            &:hover {
              cursor: pointer;
              box-shadow: inset 0px 0px 0px 2px #ebebeb;

              .right {
                svg path {
                  fill: @chatIconHoverColor;
                }
              }
            }

            .left {
              display: flex;
              align-items: center;

              .image-or-default {
                width: 82px;
                height: 82px;
                margin-right: 18px;
                border-radius: 50%;
              }

              h4 {
                margin: 0;
                font-size: 24px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #4b4c53;
              }
            }

            .right {
              width: 33px;
              height: 33px;
              background: #e9ecff;
              border-radius: 50%;

              .img-ant {
                font-size: 25px;
              }
            }
          }
        }
      }
    }

    .business-card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 25px 35px;
      margin-bottom: 28px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
      gap: 10px;

      h4 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
      }

      .text-left {
        align-self: center;

        h4 {
          color: #4b4c53;
        }

        p {
          margin: 0;
        }
      }

      .ant-tooltip-disabled-compatible-wrapper {
        margin-left: auto;
      }

      .send-button-wrapper,
      .ant-tooltip-disabled-compatible-wrapper button {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;
        height: 100%;
        margin-left: auto;
        font-size: 14px;
        font-weight: bold;
        line-height: 2;
        border-color: #1c1c1d;
        border-radius: 7px;
      }
    }

    .media-player-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

      .post-video-player {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
      }

      .post-video-player iframe {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
      }
    }

    .perks-container {
      display: flex;
      width: 100%;
      padding: 50px;
      background: @primary;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
      gap: 10px;

      h1 {
        font-size: 26px;
        font-weight: bold;
        line-height: 34px;
        color: @secondary;
      }

      p {
        display: block;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
        color: @secondary;
        opacity: 0.8;
      }

      a {
        display: flex;
      }

      button {
        display: block;
        align-self: center;
        min-width: fit-content;
        font-size: 16px;
        color: @btn-color;
        border: @btn-border;
        box-shadow: @btn-box-shadow;
        background: @btn-background;
        border-radius: @btn-border-radius;
        max-width: 80px;
      }
    }

    @media screen and (max-width: 1200px) {
      .contact-container {
        .contact-listing {
          .contact-item {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;

      .left-container,
      .right-container {
        flex: 1 1 100%;
        max-width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}

.player {
  height: 100% !important;
}
