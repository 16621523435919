.ant-table-thead {
  .ant-table-cell {
    font-size: @font-size-sm;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
}

.ant-table-tbody {
  .ant-table-row {
    &--drop-over-upward > td {
      border-top: 2px dashed @blue-200;
    }

    &--drop-over-downward > td {
      border-bottom: 2px dashed @blue-200;
    }

    &--draggable {
      cursor: move;
    }
  }
}
